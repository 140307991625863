import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addButton"]

  connect() {
    console.log("Product controller connected")
  }

  addProduct(event) {
    event.preventDefault()
    const button = event.currentTarget
    
    fetch(button.href, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    }).then(response => {
      if (response.ok) {
        // Change the icon
        const icon = button.querySelector('i')
        icon.classList.remove('fa-heart-circle-plus')
        icon.classList.add('fa-heart-circle-check')
        
        // Update the basket count
        this.updateBasketCount()
      } else {
        console.error('Failed to add product')
      }
    }).catch(error => {
      console.error('Error:', error)
    })
  }

  updateBasketCount() {
    const basketCountElement = document.querySelector('[data-navbar-target="basketCount"]')
    if (basketCountElement) {
      let currentCount = parseInt(basketCountElement.textContent) || 0
      currentCount += 1
      const event = new CustomEvent('basketCountUpdated', { detail: { count: currentCount } })
      window.dispatchEvent(event)
    }
  }
}
