import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["productList", "productTable"]

  connect() {
    console.log("Product controller connected")
    console.log("productList target:", this.productListTarget)
    console.log("productTable target:", this.productTableTarget)
    this.initializeArrows()
    this.updateBasketCount()
  }

  initializeArrows() {
    console.log('Initializing arrows')
    this.element.querySelectorAll('.icon-arrow').forEach(arrow => {
      arrow.addEventListener('click', () => {
        console.log('Arrow clicked')
        arrow.querySelector('i').classList.toggle('fa-rotate-90')
      })
    })
  }

  removeProduct(event) {
    event.preventDefault()
    console.log("removeProduct called")
    
    const button = event.currentTarget
    const productId = button.dataset.productId
    const confirmMessage = "Are you sure you want to remove this product from your list?"

    if (confirm(confirmMessage)) {
      fetch(button.form.action, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: new FormData(button.form)
      }).then(response => {
        if (response.ok) {
          const listItem = document.getElementById(`product-${productId}`)
          if (listItem) {
            listItem.remove()
          }

          Turbo.visit(window.location.href, { frame: "homepage__productTable" })
          this.updateBasketCount()
        } else {
          console.error('Failed to remove product')
        }
      }).catch(error => {
        console.error('Error:', error)
      })
    }
  }

  updateBasketCount(increment = 0) {
    let count = this.productListTarget ? this.productListTarget.children.length : 0
    count += increment
    const event = new CustomEvent('basketCountUpdated', { detail: { count: count } })
    window.dispatchEvent(event)
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}