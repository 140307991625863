$(function() {
  $("[data-toggle='dashboard-sidebar']").on('click', () => {
    var $sidebar = $("#dashboard-sidebar");
    var $mainContain = $('.main-content');
    var $header = $('.header');
    var $menuOpener = $('.header__burgerMenuOpener');

    if($sidebar.css('display') === 'flex') {
      $sidebar.css('display', 'none')
      $menuOpener.css('display', 'flex')
      $mainContain.css('padding-left', '30px');
      $header.css('margin-left', '0')
      $header.css('width', '100%');
    } else {
      $sidebar.css('display', 'flex')
      $menuOpener.css('display', 'none')
      $mainContain.css('padding-left', '270px');
      $header.css('margin-left', '240px');
      $header.css('width', 'calc(100% - 240px)');
    }
  });

  $(document).on('click', '.sidebar-toggle', () => {
    var $sidebar = $("#dashboard-sidebar");
    var $mainContain = $('.main');
    var $header = $('.header');
    var $menuOpener = $('.header__burgerMenuOpener');

    if($sidebar.hasClass('d-none')) {
      $sidebar.removeClass('d-none');
      $sidebar.addClass('d-flex');
      $menuOpener.addClass('d-none');
      $mainContain.removeClass('main-content-full');
      $header.removeClass('full-header');
    } else {
      $sidebar.addClass('d-none');
      $sidebar.removeClass('d-flex');
      $menuOpener.removeClass('d-none');
      $mainContain.addClass('main-content-full');
      $header.addClass('full-header');
    }
  });
});
