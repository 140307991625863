import { Controller } from '@hotwired/stimulus'
import 'leaflet'

export default class extends Controller {
  static targets =['placeholder']
  static values = {
    latLng: Array,
    locations: Array,
    markerUrl: String
  }

  connect () {
    this.initMap();
    this.drawMap();
    this.drawMarkers();
  }

  initMap () {
    this.map = L.map(this.placeholderTarget,{zoomDelta:0.5,zoomSnap:0.5}).setView(this.latLngValue, 11);
  }

  drawMap () {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    L.control.scale().addTo(this.map);
  }

  drawMarkers () {
    var icon = L.icon({
        iconUrl: this.markerUrlValue,
        iconSize: [26, 26],
        iconAnchor: [0, 0],
        popupAnchor: [13, 0],
    });

    this.locationsValue.forEach((location) => {
      var marker = L.marker([location.lat, location.lon], { icon: icon })
      marker.bindPopup(`<a href="${location.url}" target="_blank" class='text-capitalize'>${location.name_fixed || location.name}</a>`).openPopup();
      marker.addTo(this.map);
    })
  }
}
