import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["basketCount"]

  connect() {
    window.addEventListener('basketCountUpdated', this.updateBasketCount.bind(this))
  }

  disconnect() {
    window.removeEventListener('basketCountUpdated', this.updateBasketCount.bind(this))
  }

  updateBasketCount(event) {
    const count = event.detail.count
    this.basketCountTarget.textContent = count
    this.basketCountTarget.classList.toggle('d-none', count === 0)
  }
}
