import $ from 'jquery';
import 'select2';

function initSelect2() {
  $('.select2').select2({
    theme: 'bootstrap-5',
    width: $( this ).data( 'width' ) ? $( this ).data( 'width' ) : $( this ).hasClass( 'w-100' ) ? '100%' : 'style'
  });
}

export default initSelect2
