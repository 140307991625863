import { navigator } from "@hotwired/turbo"
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets =['searchForm', 'radiusValue']
  static values = { newVisitor: { type: Boolean, default: false }}

  connect () {
    document.getElementById('search-location-modal')?.addEventListener('show.bs.modal', this.setGoogleAutoComplete);
    document.getElementById('search-location-modal')?.addEventListener('hidden.bs.modal', this.resetForm);
    this.checkNewVisitor();
    this.updateRadiusValue({ target: document.getElementById('radiusSlider') });
  }

  checkNewVisitor() {
    if (this.newVisitorValue) {
      document.querySelectorAll('[data-bs-target="#search-location-modal"]')[0]?.click();
    }
  }

  resetForm() {
    document.getElementById('SearchBox').value = '';
    document.getElementById('cityLat').value = '';
    document.getElementById('cityLng').value = '';
    document.getElementById('provinceCode').value = '';
  }

  setGoogleAutoComplete() {
    /*
    var defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(69.895317,-162.247163),
      new google.maps.LatLng(26.034767,-46.299439)
    );
    */
    var input = document.getElementById('SearchBox');
    var options = {
      //bounds: defaultBounds,
      //types: ['address'], // Restrict results to addresses (including streets)
      componentRestrictions: { country: 'CA' } // Restrict to Canadian locations
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
        var place = autocomplete.getPlace();
        document.getElementById('cityLat').value = place.geometry.location.lat();
        document.getElementById('cityLng').value = place.geometry.location.lng();
        //alert("This function is working!");
        //alert(input.value);
        // Extract the full name of the province or state
        var provinceCode = '';
        if (place.address_components) {
            for (var i = 0; i < place.address_components.length; i++) {
                var component = place.address_components[i];
                if (component.types.indexOf('administrative_area_level_1') !== -1) {
                    provinceCode = component.short_name;
                    break;
                }
            }
        }
        document.getElementById('provinceCode').value = provinceCode;
    });
  }

  turboSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    navigator.submitForm(this.searchFormTarget);
  }

  handleSubmitEnd(e) {
    if (e.detail.success === true) {
      window.location.reload();
    }
  }

  updateRadiusValue(event) {
    const value = event.target.value;
    this.radiusValueTarget.textContent = value + ' km';
  }
}
